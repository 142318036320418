import Header from "../components/header"
import cover from '../assets/images/cover.svg'
import chrome from '../assets/images/chrome.svg'
import './index.scss'
export default () => {
    return (
        <div className="w100 all-content flex flex-column">
            <Header/>
            <div className="flex flex-center p-r-120 p-l-268 gap-25 content flex-1">
                 <div className="tl">
                    <span className="fz-63 fwb cb"> Raise Your Character Take Profit on Twitter</span>
                    <div className="fz-25 cb">Install the extension and receive your character  directly on the Twitter interface, take good care of your character to earn profits.</div>
                    <div className="cb flex flex-center gap-18 p-t-80">Add to browser <div className="chrome flex flex-center gap-10">
                         <img src={chrome} alt="" /> Chrome
                        </div></div>
                 </div>
                 <img src={cover} alt="" />
            </div>
            <div className="footer cbl p-24 gap-35 flex flex-middle">
                <span>Borrow Policy</span>
                <span>Privacy Policy</span>
                <span>Term of use</span>
            </div>


        </div>
    )
}