import logo from '../assets/images/logo.svg'
import twitter from '../assets/images/twitter.svg'
import './index.scss'
export default () => {
    return <div className='flex w100 flex-between p-40 flex-center'>
          <img src={logo} alt="" />
          <div className="flex fz-18 cb gap-70">
              <div className='nav-item active flex  flex-middle'>Home <span className='line'></span></div>
              <div className='nav-item active'>Borrow </div>
          </div>
          <img src={twitter} alt="" />

    </div>
}